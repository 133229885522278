/* General, may be used in other places aside from workdisplay */

.displayContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent
    }

.rowContainer {
    display: flex;
    width: 100%;
    padding: 2.5%;
    flex-direction: row;
}

#projectRowsContainer {
    background-color: transparent;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: space-evenly;
}

.workElement {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workRow {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    padding-top: 1%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    padding-bottom: 1%;
    border-bottom: 1.25px solid transparent;
    border-top: 1.5px solid transparent;
    color: #212119;
    transition: border-bottom 0.5s, border-top 0.5s, background-color 0.75s, color 0.75s;
}


.workRow:hover {
    border-top: 1px solid #212119;
    border-bottom: 1px solid #212119;
    background-color: transparent;
    color: #212119;
    cursor: none;
}


/* images and such inside the container */

.elementContainer {
        display: flex;
        flex: 1.5;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 2%;
    }

.elementImage {
    height: 100%;
    width: 0%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.workColorOverlay {
    height: 100%;
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e31212;
    overflow: hidden;
}

.workTextOverlay {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 41.76px;
    font-weight: 400;
    flex: 2;
    overflow: hidden
}

.caseStudyButton {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    height: auto;
    color: #212119;
    font-size: 20px;
    font-weight: 400;
    background-color: transparent;
    width: 100%;
    border-radius: 2px;
    transition: color 0.33s;
}

.caseStudyButton:hover{
    color: #e31212;
}

.workTextContainer {
    
    font-size: 56px;
    overflow: hidden
}

.workSubtitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 0px;
    opacity: 0.0;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 87.5%;
    padding-left: 0.5%;
    overflow: hidden;
}

#indexImage {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 66.67%;
    right: 1%;
    top: 10%;
    z-index: -1;
    background-color: transparent;
}

.indexMedia {
    height: 100%;
    width: 90%;
     background-size: contain;
    background-repeat: no-repeat;
    background-position: center;   
}

@media only screen and (max-width: 800px){
    .indexMedia {
        display: none;
    }
}

@media only screen and (max-width: 500px){
    .rowContainer {
        flex-direction: column;
    }

    .workRow {
        flex-direction: column;
        height: 225px;
        overflow: hidden
    }

    .caseStudyButton {
        font-size: 12px;
    }

    .workSubtitleContainer {
        width: 100%;
        height: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .elementContainer {
        width: 100%;
    }
    .elementImage {
        width: 100%;
        height: auto;
        background-size: cover;
        flex: 3;
    }

    #largeElement {
        margin-bottom: 2%;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

}