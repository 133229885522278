/* General */

body {
    background-color: white;
    overflow-x: hidden;
}



/* Specific to detailDisplay */

.detailRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 1.25%;
    margin-bottom: 1.25%;
    background-color: #fbfbfb;
}

#medium {
    height: 300px;
}

#padding {
    padding-top: 3%;
    padding-bottom: 3%;
    flex-direction: column;
}

#large {
    height: 450px;
}

#shrinkSizes {
    padding-left: 0%;
    padding-right: 0%;
}

    #xLarge {
    min-height: 650px;
    height: 100vh;
}



.row-content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

/* For title content */
.detailTitleOverlay {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2.5%;
}

.detailNavButton {
   color: #212119;
}

#detailTitle {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 112px;
    font-weight: 300;
    transition: transform 1s;
}

#detailTitle:hover {
    
    cursor: default;
}

#detailBackButton {
    transition: transform 1s;
}
#detailBackButton:hover {
    transform: skew(0deg, -2deg);
    cursor: pointer;
}

#detailText {
    font-size: 1.4rem;
}

/* CSS for brief container */

.brief-title-container {
    display: flex;
    padding: 3.5%;
    color: #212119;
}

.brief-detail-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.brief-detail-components {
    flex-direction: column;
    padding: 3.5%;
    padding-right: 7%;
    padding-left: 7%;
    height: 100%;
    text-align: left;
    align-items: flex-start;
}

#brief-title-text {
    margin-bottom: 25px;
}

/* Image container constraints */

.detailImageContainer {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#product01 {
    height: 100%;
    width: 100%;
     background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#product02 { 
 background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Large text slide */

.largeTextContainer {
    width: 100%;
    padding: 3.5%;
    text-align: left;

}

/*strategy container restraints*/

.strategyRowContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
    margin-top: 1%;
}

.strategyCardContent {
    display: flex;
    width: 100%;
}

.content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#strategyCardNumber {
    flex: 2;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5%;
    color: black;
}

#strategyCardTitle {
    flex: 4;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3.5%;
    color: black;
}

.strategyTextContainer {
    padding-bottom: 3.5%;
    padding-top: 1.75%;
}



/* For experience slide */


#textSplit {
   color: #212119;
   background-color: white;
}

#imageSplit {
     flex: 1.5;
}

.experienceImageContainer {
    background-color: #efefef;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#slideHover:hover {
    cursor: pointer;
}

/* For result container */
.result-title-container {
    display: flex;
    color: #212119;
}

.featuresContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-top: 3.5%;
    padding-bottom: 3.5%;
}

#logoContainer {
    bottom: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: white;
    border-top: 1.5px solid #212119;
    }

#logoImage {
    height: 100%;
    width: 20%;
    background-image: url("../media/logo-black.svg");
background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

/*title constraints */

/* Mobile constraints */
@media only screen and (max-width: 500px){
    .detailNavButton {
        max-width: 85%;
    }

    .featuresContainer {
        flex-direction: row
    }

    .detailTitleOverlay {
        padding-bottom: 1.5%;
    }

    #xLarge {
        height: auto;
        min-height: 0px;
    }

    #detailTitle {
        width: 100%;
    }

    .row-content-wrapper {
        flex-direction: column;
        height: 100vh;
    }
    .brief-detail-rows {
        flex-direction: column;
    }

    .brief-detail-components {
        padding-left: 3.5%;
        padding-right: 3.5%;
    }

    .largeTextContainer {
        padding: 2.5%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .brief-title-container {
        padding: 2.5%
    }

    .strategyCardContainer {
        height: 170px;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .strategyTextContainer {
        padding-top: 3.5%;
        padding-bottom: 10.5%;
    }

  
}