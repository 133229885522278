.techContainer {
    display: flex;
    width: 100%;
    max-width: 1600px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    color: white;
    background-color: #212119;
    padding-top: 3%;
    padding-bottom: 3%;
}

.techRowContainer {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
}

#code-title-container {
    padding-left: 2.5%;
    justify-content: flex-start;
    align-items: center;
      color: white;
}

.columnFlex {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.wrap-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    width: 98%;
    overflow-y: hidden;
  /*  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 500px;
    height: 75vh;
    width: 100%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    overflow-y: hidden;*/
}
.techButtons {
    flex-direction: row; 
     width: 100%;
    flex: 1.5;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-right: 7.5%;
    padding-left: 7.5%;
}

.techContent {
    position: relative;
    flex: 8.5;
    height: 450px;
    max-height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    border-right: 2.5px solid transparent;
    border-left: 2.5px solid transparent;
    margin-top: 1%;
    margin-bottom: 1%;
}

#squarePath {
     stroke-dasharray: 100;
    stroke-dashoffset: 100;
}

.techNavButtons {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    margin: 1.75%;
    padding: 1%;
    padding-left: 2.5%;
    width: 100%;
    background-color: transparent;
    border: 1.25px solid white;
    color: white;
    transition: background-color .5s, color .5s, border .5s;
    outline: none;
}

.techNavButtons:hover {
   background-color: #E06647;
   color: white;
   border: 1.25px solid transparent;
}

.techTextContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    color: white;
        background-color: #1e1e1e;
}

.techTextDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.5%;
    justify-content: space-evenly;
    height: 100%;
    overflow-y: scroll;
    flex: 3;
    color: white;
}

.techTextImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 7;
    background-color: #1E1E1E;
}

/* Mobile constraints */
@media only screen and (max-width: 850px){
    .wrap-container{
        flex-direction: column;
        width: 100vw;
        min-height: 500px;
        height: 100%;
    }

    .techButtons {
        flex-direction: row;
        width: 100%;
    }

    .techContent {
        width: 100%;
        height: auto;
    }

    .techTextContainer {
        flex-direction: column-reverse;
        height: auto;
    }

    .tech-paragraph-wrapper {
        flex-direction: row;
    }

    .techNavButtons {
        margin-left: 2%;
        margin-right: 2%;
        padding: 1%;
    }

}