*, ul{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  background-color: white;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');


h1 {
  font-size: 106px;
  font-weight: 500;
  color: #212119
}

h2 {
  font-size: 65px;
  font-weight: 500;
  color: #212119
}

h3 {
  font-size: 40px;
  font-weight: 400;
  color: #212119;
}

h4 {
  font-size: 25px;
  font-weight: 400;
  color: #212119;
}

h5 {
    font-size: 18px;
    font-weight: 400;
      color: #212119;
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
      color: #212119;
  }


p {
  font-size: 12px;
  font-weight: 300;
    color: #212119;
}


.loadOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   z-index: 20;
  width: 100vw;
  height: 100vh; 
  top: 0;
  left: 0;
  background-color: #fafafad8;
  overflow: hidden
}

.loadImageContainer {
  width: 100%;
  height: 100%;
  transform: scale(0.65,0.65);
  background-image: url('./media/logo-rotate.gif');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.toTopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 35px;
  width: 35px;
  z-index: 10;
  bottom: 2%;
  right: 1%;
  border: none;
  outline: none;
  background-color: #15161265;
  transition: background-color 0.5s;
}

.toTopButton:hover {
  background-color: #151612;
  cursor: pointer;
}

/* Mobile constraints */

@media only screen and (max-width: 675px){

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 35px;
  }
  
  h3 {
    font-size: 24px;
  }
  
   h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 11px;
    font-weight: 400;
  }


    h6 {
    font-size: 11px;
  }

  p {
    font-size: 10px;
  }
}
