/* General */
body {
    background-color: white;
    overflow-x: hidden;
}

.displayContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    }

/* For header components */

    .aboutTitle {
        padding-left: 3.5%;
        padding-right: 3.5%;
        font-size: 56px;
        font-weight: 500;
    }

.headerImage {
    display: flex;
}

.headerText {
    padding-left: 3.5%;
    padding-right: 3.5%;
    margin-bottom: 1%;
}
.fundRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#fundRowTitle {
    justify-content: flex-start;
    padding-bottom: 1.75%;
}

/* For button */

.aboutButtonRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 3.5%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    border-bottom: 1.5px solid black;
}

/* For card components */

.fundCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 425px;
    width: 50%;
    color: white;
}

.fundamentalText {
    color: transparent
}

.fundCardContainer:hover {
    cursor: default;
    box-shadow: 0 0 7px 7px transparent;
}

#fundCardContent{
    padding: 3%;
    opacity: 0;
}

#fundCardTitle {
    padding: 3.5%;
    font-size: 24px;
    font-weight: 600;
}


.resume-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2.5%;
}

/* For contact */
.contactComponent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 2.5%;
    padding-left: 3.5%;
    height: auto
}

/* For gallery */

.galleryContainer {
    border-bottom: 1.5px solid black;
    margin-bottom: 1.5%;
}

.galleryRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2.5%;
}

.galleryElementContainer {
    height: 100%;
    width: 50%;
    transform: scale(0.95);
}

.getInTouchButton {
    border: 1.5px solid black;
    color: white;
    padding: 1%;
    padding-right: 3.5%;
    font-weight: 400;
}


@media only screen and (max-width: 900px){

    #fundCardTitle {
        font-size: 20px;
    }

}

@media only screen and (max-width: 500px){

    .headerText {
        margin-top: 2.5%;
    }

    .fundRowContainer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    #fundRowTitle {
    display: flex;
    padding-left: 2.5%;
    justify-content: flex-start;
}

    .fundCardContainer {
        width: 93%;
        height: 250px;
        margin-bottom: 3.5%;
        
    }

    .resume-container {
        width: 100%;
        transform: scale(1);
    }

    .contactComponent {
        align-items: flex-start;
    }

    .galleryRowContainer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .galleryElementContainer {
        width: 100%;
        height: 100%;
    }

    .aboutTitle {
        font-size: 30px;
        min-height: 125px;
        height: auto;
    }

    #fundCardContent {
        font-size: 10px;
        opacity: 1;
        line-height: 115%;
    }

}