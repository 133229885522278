html {
    height: 100%;
     overflow: auto
}

body {
    height: 100%;
    background-color: white;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100VW;
    max-width: 1400px;
    justify-content: center;
    align-items: center;

}

#detailContainer {
    background-color: #212119;
}

