/* General setup */
.navBar {
    height: auto;
    width: 100VW;
    max-width: 1400px;
    padding-top: 1%;
    padding-bottom: 5%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navBar-desktop {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}

.navBar-mobile{
    width: 100%;
    height: auto;
    display: none; 
}

.navImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 25%; 
}

.navDropdown {
    display: none;
}

.navLinksContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    width: 100%; 
}

/* Logo and relevant content */
.navLink {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navImage {
    width: 100%;
    height: 145px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

#logo-black {
 background-image: url("../media/logo-black.svg");
}

/* Buttons and relevant content */
.navButtons {
    font-size: 30px;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
    color: #212119;
    padding: 0.5%;
    padding-bottom: 0%;
    height: 38.15%;
    transition: color 0.5s;
}


.navButtons:hover {
    color: #E06647;
    font-weight: 500;
    cursor: pointer;
}

#navTitle {  
    font-size: 56px;
    font-weight: 400;
    text-align: left;
    transition: transform 1s;
}

#navTitle:hover {
    transform: skew(0deg, -2deg);
    color:  black;
}

#navContact {
    color: #E06647;
}


@media only screen and (max-width: 825px){
    .navButtons {
        font-size: 25.76px;
    }

    #navTitle {
        font-size: 34px;
    }
}


@media only screen and (max-width: 475px){


    .navBar{
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .navBar-desktop{
        display: none;
    }

    .navBar-mobile{
        justify-content: space-between;
        display: flex;
    }

    .navDropdown {
        display: flex;
        justify-content: flex-end;
    }

    .navImage{
      background-position: left;
      width: 100%;
      margin-left: 2%;
    }

    #logoBlack {
        
        transform: scale(0.8);
    }

}