@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* General setup */
.navBar {
    height: auto;
    width: 100VW;
    max-width: 1400px;
    padding-top: 1%;
    padding-bottom: 5%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navBar-desktop {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}

.navBar-mobile{
    width: 100%;
    height: auto;
    display: none; 
}

.navImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 25%; 
}

.navDropdown {
    display: none;
}

.navLinksContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    width: 100%; 
}

/* Logo and relevant content */
.navLink {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navImage {
    width: 100%;
    height: 145px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

#logo-black {
 background-image: url(/static/media/logo-black.82923a62.svg);
}

/* Buttons and relevant content */
.navButtons {
    font-size: 30px;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
    color: #212119;
    padding: 0.5%;
    padding-bottom: 0%;
    height: 38.15%;
    transition: color 0.5s;
}


.navButtons:hover {
    color: #E06647;
    font-weight: 500;
    cursor: pointer;
}

#navTitle {  
    font-size: 56px;
    font-weight: 400;
    text-align: left;
    transition: transform 1s;
}

#navTitle:hover {
    transform: skew(0deg, -2deg);
    color:  black;
}

#navContact {
    color: #E06647;
}


@media only screen and (max-width: 825px){
    .navButtons {
        font-size: 25.76px;
    }

    #navTitle {
        font-size: 34px;
    }
}


@media only screen and (max-width: 475px){


    .navBar{
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .navBar-desktop{
        display: none;
    }

    .navBar-mobile{
        justify-content: space-between;
        display: flex;
    }

    .navDropdown {
        display: flex;
        justify-content: flex-end;
    }

    .navImage{
      background-position: left;
      width: 100%;
      margin-left: 2%;
    }

    #logoBlack {
        
        transform: scale(0.8);
    }

}
/* General */

body {
    background-color: white;
    overflow-x: hidden;
}



/* Specific to detailDisplay */

.detailRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 1.25%;
    margin-bottom: 1.25%;
    background-color: #fbfbfb;
}

#medium {
    height: 300px;
}

#padding {
    padding-top: 3%;
    padding-bottom: 3%;
    flex-direction: column;
}

#large {
    height: 450px;
}

#shrinkSizes {
    padding-left: 0%;
    padding-right: 0%;
}

    #xLarge {
    min-height: 650px;
    height: 100vh;
}



.row-content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

/* For title content */
.detailTitleOverlay {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2.5%;
}

.detailNavButton {
   color: #212119;
}

#detailTitle {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 112px;
    font-weight: 300;
    transition: transform 1s;
}

#detailTitle:hover {
    
    cursor: default;
}

#detailBackButton {
    transition: transform 1s;
}
#detailBackButton:hover {
    transform: skew(0deg, -2deg);
    cursor: pointer;
}

#detailText {
    font-size: 1.4rem;
}

/* CSS for brief container */

.brief-title-container {
    display: flex;
    padding: 3.5%;
    color: #212119;
}

.brief-detail-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.brief-detail-components {
    flex-direction: column;
    padding: 3.5%;
    padding-right: 7%;
    padding-left: 7%;
    height: 100%;
    text-align: left;
    align-items: flex-start;
}

#brief-title-text {
    margin-bottom: 25px;
}

/* Image container constraints */

.detailImageContainer {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#product01 {
    height: 100%;
    width: 100%;
     background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#product02 { 
 background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Large text slide */

.largeTextContainer {
    width: 100%;
    padding: 3.5%;
    text-align: left;

}

/*strategy container restraints*/

.strategyRowContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
    margin-top: 1%;
}

.strategyCardContent {
    display: flex;
    width: 100%;
}

.content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#strategyCardNumber {
    flex: 2 1;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5%;
    color: black;
}

#strategyCardTitle {
    flex: 4 1;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3.5%;
    color: black;
}

.strategyTextContainer {
    padding-bottom: 3.5%;
    padding-top: 1.75%;
}



/* For experience slide */


#textSplit {
   color: #212119;
   background-color: white;
}

#imageSplit {
     flex: 1.5 1;
}

.experienceImageContainer {
    background-color: #efefef;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#slideHover:hover {
    cursor: pointer;
}

/* For result container */
.result-title-container {
    display: flex;
    color: #212119;
}

.featuresContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-top: 3.5%;
    padding-bottom: 3.5%;
}

#logoContainer {
    bottom: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: white;
    border-top: 1.5px solid #212119;
    }

#logoImage {
    height: 100%;
    width: 20%;
    background-image: url(/static/media/logo-black.82923a62.svg);
background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

/*title constraints */

/* Mobile constraints */
@media only screen and (max-width: 500px){
    .detailNavButton {
        max-width: 85%;
    }

    .featuresContainer {
        flex-direction: row
    }

    .detailTitleOverlay {
        padding-bottom: 1.5%;
    }

    #xLarge {
        height: auto;
        min-height: 0px;
    }

    #detailTitle {
        width: 100%;
    }

    .row-content-wrapper {
        flex-direction: column;
        height: 100vh;
    }
    .brief-detail-rows {
        flex-direction: column;
    }

    .brief-detail-components {
        padding-left: 3.5%;
        padding-right: 3.5%;
    }

    .largeTextContainer {
        padding: 2.5%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .brief-title-container {
        padding: 2.5%
    }

    .strategyCardContainer {
        height: 170px;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .strategyTextContainer {
        padding-top: 3.5%;
        padding-bottom: 10.5%;
    }

  
}
/* General, may be used in other places aside from workdisplay */

.displayContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent
    }

.rowContainer {
    display: flex;
    width: 100%;
    padding: 2.5%;
    flex-direction: row;
}

#projectRowsContainer {
    background-color: transparent;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: space-evenly;
}

.workElement {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workRow {
    display: flex;
    flex: 1 1;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    padding-top: 1%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    padding-bottom: 1%;
    border-bottom: 1.25px solid transparent;
    border-top: 1.5px solid transparent;
    color: #212119;
    transition: border-bottom 0.5s, border-top 0.5s, background-color 0.75s, color 0.75s;
}


.workRow:hover {
    border-top: 1px solid #212119;
    border-bottom: 1px solid #212119;
    background-color: transparent;
    color: #212119;
    cursor: none;
}


/* images and such inside the container */

.elementContainer {
        display: flex;
        flex: 1.5 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 2%;
    }

.elementImage {
    height: 100%;
    width: 0%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.workColorOverlay {
    height: 100%;
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e31212;
    overflow: hidden;
}

.workTextOverlay {
    width: 100%;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 41.76px;
    font-weight: 400;
    flex: 2 1;
    overflow: hidden
}

.caseStudyButton {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    height: auto;
    color: #212119;
    font-size: 20px;
    font-weight: 400;
    background-color: transparent;
    width: 100%;
    border-radius: 2px;
    transition: color 0.33s;
}

.caseStudyButton:hover{
    color: #e31212;
}

.workTextContainer {
    
    font-size: 56px;
    overflow: hidden
}

.workSubtitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 0px;
    opacity: 0.0;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 87.5%;
    padding-left: 0.5%;
    overflow: hidden;
}

#indexImage {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 66.67%;
    right: 1%;
    top: 10%;
    z-index: -1;
    background-color: transparent;
}

.indexMedia {
    height: 100%;
    width: 90%;
     background-size: contain;
    background-repeat: no-repeat;
    background-position: center;   
}

@media only screen and (max-width: 800px){
    .indexMedia {
        display: none;
    }
}

@media only screen and (max-width: 500px){
    .rowContainer {
        flex-direction: column;
    }

    .workRow {
        flex-direction: column;
        height: 225px;
        overflow: hidden
    }

    .caseStudyButton {
        font-size: 12px;
    }

    .workSubtitleContainer {
        width: 100%;
        height: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .elementContainer {
        width: 100%;
    }
    .elementImage {
        width: 100%;
        height: auto;
        background-size: cover;
        flex: 3 1;
    }

    #largeElement {
        margin-bottom: 2%;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

}
/* General */
body {
    background-color: white;
    overflow-x: hidden;
}

.displayContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    }

/* For header components */

    .aboutTitle {
        padding-left: 3.5%;
        padding-right: 3.5%;
        font-size: 56px;
        font-weight: 500;
    }

.headerImage {
    display: flex;
}

.headerText {
    padding-left: 3.5%;
    padding-right: 3.5%;
    margin-bottom: 1%;
}
.fundRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#fundRowTitle {
    justify-content: flex-start;
    padding-bottom: 1.75%;
}

/* For button */

.aboutButtonRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 3.5%;
    padding-left: 3.5%;
    padding-right: 3.5%;
    border-bottom: 1.5px solid black;
}

/* For card components */

.fundCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 425px;
    width: 50%;
    color: white;
}

.fundamentalText {
    color: transparent
}

.fundCardContainer:hover {
    cursor: default;
    box-shadow: 0 0 7px 7px transparent;
}

#fundCardContent{
    padding: 3%;
    opacity: 0;
}

#fundCardTitle {
    padding: 3.5%;
    font-size: 24px;
    font-weight: 600;
}


.resume-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2.5%;
}

/* For contact */
.contactComponent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 2.5%;
    padding-left: 3.5%;
    height: auto
}

/* For gallery */

.galleryContainer {
    border-bottom: 1.5px solid black;
    margin-bottom: 1.5%;
}

.galleryRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2.5%;
}

.galleryElementContainer {
    height: 100%;
    width: 50%;
    transform: scale(0.95);
}

.getInTouchButton {
    border: 1.5px solid black;
    color: white;
    padding: 1%;
    padding-right: 3.5%;
    font-weight: 400;
}


@media only screen and (max-width: 900px){

    #fundCardTitle {
        font-size: 20px;
    }

}

@media only screen and (max-width: 500px){

    .headerText {
        margin-top: 2.5%;
    }

    .fundRowContainer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    #fundRowTitle {
    display: flex;
    padding-left: 2.5%;
    justify-content: flex-start;
}

    .fundCardContainer {
        width: 93%;
        height: 250px;
        margin-bottom: 3.5%;
        
    }

    .resume-container {
        width: 100%;
        transform: scale(1);
    }

    .contactComponent {
        align-items: flex-start;
    }

    .galleryRowContainer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .galleryElementContainer {
        width: 100%;
        height: 100%;
    }

    .aboutTitle {
        font-size: 30px;
        min-height: 125px;
        height: auto;
    }

    #fundCardContent {
        font-size: 10px;
        opacity: 1;
        line-height: 115%;
    }

}
/* General */


/* for TypedContainer */

.typedContainer {
    margin-left: 3.5%;
    margin-right: 3.5%;
    width: 100%;
    min-height: 200px;
    height: auto;
    padding: 1.5%;
    font-size: 65px;
    font-weight: normal;
    color: #E06647;
}


@media only screen and (max-width: 475px){
    
    .typedContainer {
        font-size: 25.76px;
    }


}
html {
    height: 100%;
     overflow: auto
}

body {
    height: 100%;
    background-color: white;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100VW;
    max-width: 1400px;
    justify-content: center;
    align-items: center;

}

#detailContainer {
    background-color: #212119;
}


.techContainer {
    display: flex;
    width: 100%;
    max-width: 1600px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    color: white;
    background-color: #212119;
    padding-top: 3%;
    padding-bottom: 3%;
}

.techRowContainer {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
}

#code-title-container {
    padding-left: 2.5%;
    justify-content: flex-start;
    align-items: center;
      color: white;
}

.columnFlex {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.wrap-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    width: 98%;
    overflow-y: hidden;
  /*  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 500px;
    height: 75vh;
    width: 100%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    overflow-y: hidden;*/
}
.techButtons {
    flex-direction: row; 
     width: 100%;
    flex: 1.5 1;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-right: 7.5%;
    padding-left: 7.5%;
}

.techContent {
    position: relative;
    flex: 8.5 1;
    height: 450px;
    max-height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    border-right: 2.5px solid transparent;
    border-left: 2.5px solid transparent;
    margin-top: 1%;
    margin-bottom: 1%;
}

#squarePath {
     stroke-dasharray: 100;
    stroke-dashoffset: 100;
}

.techNavButtons {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    margin: 1.75%;
    padding: 1%;
    padding-left: 2.5%;
    width: 100%;
    background-color: transparent;
    border: 1.25px solid white;
    color: white;
    transition: background-color .5s, color .5s, border .5s;
    outline: none;
}

.techNavButtons:hover {
   background-color: #E06647;
   color: white;
   border: 1.25px solid transparent;
}

.techTextContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    color: white;
        background-color: #1e1e1e;
}

.techTextDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.5%;
    justify-content: space-evenly;
    height: 100%;
    overflow-y: scroll;
    flex: 3 1;
    color: white;
}

.techTextImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 7 1;
    background-color: #1E1E1E;
}

/* Mobile constraints */
@media only screen and (max-width: 850px){
    .wrap-container{
        flex-direction: column;
        width: 100vw;
        min-height: 500px;
        height: 100%;
    }

    .techButtons {
        flex-direction: row;
        width: 100%;
    }

    .techContent {
        width: 100%;
        height: auto;
    }

    .techTextContainer {
        flex-direction: column-reverse;
        height: auto;
    }

    .tech-paragraph-wrapper {
        flex-direction: row;
    }

    .techNavButtons {
        margin-left: 2%;
        margin-right: 2%;
        padding: 1%;
    }

}
*, ul{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  background-color: white;
}


h1 {
  font-size: 106px;
  font-weight: 500;
  color: #212119
}

h2 {
  font-size: 65px;
  font-weight: 500;
  color: #212119
}

h3 {
  font-size: 40px;
  font-weight: 400;
  color: #212119;
}

h4 {
  font-size: 25px;
  font-weight: 400;
  color: #212119;
}

h5 {
    font-size: 18px;
    font-weight: 400;
      color: #212119;
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
      color: #212119;
  }


p {
  font-size: 12px;
  font-weight: 300;
    color: #212119;
}


.loadOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   z-index: 20;
  width: 100vw;
  height: 100vh; 
  top: 0;
  left: 0;
  background-color: #fafafad8;
  overflow: hidden
}

.loadImageContainer {
  width: 100%;
  height: 100%;
  transform: scale(0.65,0.65);
  background-image: url(/static/media/logo-rotate.d989fe5e.gif);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.toTopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 35px;
  width: 35px;
  z-index: 10;
  bottom: 2%;
  right: 1%;
  border: none;
  outline: none;
  background-color: #15161265;
  transition: background-color 0.5s;
}

.toTopButton:hover {
  background-color: #151612;
  cursor: pointer;
}

/* Mobile constraints */

@media only screen and (max-width: 675px){

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 35px;
  }
  
  h3 {
    font-size: 24px;
  }
  
   h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 11px;
    font-weight: 400;
  }


    h6 {
    font-size: 11px;
  }

  p {
    font-size: 10px;
  }
}

