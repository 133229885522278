/* General */


/* for TypedContainer */

.typedContainer {
    margin-left: 3.5%;
    margin-right: 3.5%;
    width: 100%;
    min-height: 200px;
    height: auto;
    padding: 1.5%;
    font-size: 65px;
    font-weight: normal;
    color: #E06647;
}


@media only screen and (max-width: 475px){
    
    .typedContainer {
        font-size: 25.76px;
    }


}